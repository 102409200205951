<template>
  <div>
    <table class="small-font">
      <thead>
        <tr>
          <th v-if="runNumber > 1">Manche</th>
          <th>Total</th>
          <th>Temps</th>
          <th>Pénalités</th>
          <th v-for="n in gateNumber" :key="'penHeader'+n">{{n}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(run, index) in runs" :key="'run'+index" class="no-v-padding text-center">
          <td v-if="runNumber > 1">{{index+1}}</td>
          <td>
            {{run.status === 'OK' && !Number.isNaN(run.total) ? run.total.toFixed(2) : run.status}}
          </td>
          <td>{{run.time}}</td>
          <td>{{run.penalties}}</td>
          <td
            v-for="(penalty, indexPen) in run.penaltiesDetail"
            :key="'run'+index+'pen'+indexPen"
            :class="[
              {'green-bg': penalty===0, 'yellow-bg': penalty===2, 'red-bg': penalty===50},
              'penalty'
            ]"
          >
            {{penalty}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    runNumber() {
      return Array.isArray(this.runs) ? this.runs.length : 0;
    },
    gateNumber() {
      const gateNumber = this.runs.map(
        (e) => (Array.isArray(e.penaltiesDetail) ? e.penaltiesDetail.length : 0),
      );
      return Math.max(...gateNumber);
    },
  },
  created: () => {
  },
  methods: {
  },
  props: ['runs'],
};
</script>

<style scoped>
.small-font {
  font-size:0.8em;
}
.penalty {
  width:20px;
  padding:0px;
}
.no-v-padding td {
  padding-top:0px;
  padding-bottom:0px;
}
.green-bg {
  background-color: lightgreen;
}
.yellow-bg {
  background-color: yellow;
}
.red-bg {
  background-color: red;
}

</style>
