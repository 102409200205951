<template>
  <b-overlay :show="showOverlay">
    <b-row>
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-name"
            label="Intitulé:"
            label-for="input-name"
            label-cols-lg="1"
          >
            <b-form-input
              id="input-name"
              v-model="race.raceName"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-date"
            label="Date:"
            label-for="race-datepicker"
            label-cols-lg="1"
          >
            <b-form-datepicker
              id="race-datepicker"
              v-model="race.date"
              class="mb-2"
              locale="fr"
              start-weekday="1"
              label-help=""
              @change="computeRace"
          >
            </b-form-datepicker>
          </b-form-group>
          <b-form-group
            id="input-group-event"
            label="Evènement :"
            label-for="event"
            label-cols-lg="1"
          >
            <b-form-select
              id="event"
              v-model="selectedEvent"
              :options="events"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-event"
            label="Calcul de point :"
            label-for="computation"
            label-cols-lg="1"
          >
            <b-form-select
              id="computation"
              v-model="selectedComputation"
              :options="computations"
              @change="computeRace"
              required
            ></b-form-select>
        </b-form-group>
          <b-alert v-model="failed" variant="danger">
            Echec de l'enregistrement !
          </b-alert>
          <b-button
            :disabled="!readyToSave"
            type="submit" variant="primary" @click="validateRace">
            Enregistrer
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <br/>
    <h4>Aperçu des résultats :</h4>

    <hr/>
    <race-results :results="race.results" :raceName="race.raceName"></race-results>
  </b-overlay>
</template>

<script>
import RaceResults from '../../components/RaceResults.vue';

export default {
  name: 'RacePendingEdit',
  data() {
    return {
      showOverlay: false,
      modalShow: false,
      race: {},
      events: [],
      selectedEvent: null,
      failed: false,
      selectedComputation: null,
      computations: [],
    };
  },
  async created() {
    this.showOverlay = true;
    await Promise.all([
      this.getPendingRace(),
      this.getEventList(),
      this.getComputations(),
    ]);
    this.showOverlay = false;
  },
  components: {
    RaceResults,
  },
  computed: {
    readyToSave() {
      return this.selectedEvent !== null && this.selectedComputation !== null;
    },
  },
  methods: {
    async getPendingRace() {
      const response = await this.axios.get(`/race/pending/${this.$route.params.id}`);
      if (response && response.data) {
        this.race = response.data;
      }
    },
    async getEventList() {
      // We get all the events before today
      const before = this.$DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
      const response = await this.axios.get(`/event/yearPrior/${before}`);
      if (response && response.data) {
        this.events = response.data.map((e) => {
          const eventDate = this.$DateTime.fromISO(e.date).toFormat('yyyy-MM-dd');
          return {
            // eslint-disable-next-line no-underscore-dangle
            value: e._id,
            text: `${eventDate} - ${e.name} - ${e.place}`,
          };
        });
      }
    },
    async onSubmit() {
      this.failed = false;
      this.showOverlay = true;
      this.showOverlay = false;
    },
    async getComputations() {
      const response = await this.axios.get('/computation/list');
      if (response && response.data) {
        this.computations = response.data.map((comp) => ({
          value: `${comp.computationId}-${comp.variantId}`,
          text: `${comp.computationName} - ${comp.variantName}`,
          computationId: comp.computationId,
          variantId: comp.variantId,
        }));
      }
    },
    async computeRace() {
      if (this.selectedComputation) {
        this.showOverlay = true;
        const computation = this.computations.find(
          (c) => c.value === this.selectedComputation,
        );
        const response = await this.axios.post(
          '/computation/race',
          {
            id: this.$route.params.id,
            date: this.race.date,
            computationId: computation.computationId,
            variantId: computation.variantId,
          },
        );
        if (response.data && Array.isArray(response.data.results)) {
          this.race.results = response.data.results;
        }
        this.showOverlay = false;
      }
    },
    async validateRace() {
      if (this.readyToSave) {
        this.showOverlay = true;
        const computation = this.computations.find(
          (c) => c.value === this.selectedComputation,
        );
        const response = await this.axios.post(
          '/race/pending/validate',
          {
            id: this.$route.params.id,
            raceName: this.raceName,
            date: this.race.date,
            computationId: computation.computationId,
            variantId: computation.variantId,
            eventId: this.selectedEvent,
          },
        );
        if (response.data) {
          // console.log(response.data.id);
        }
        this.showOverlay = true;
      }
    },
  },
};
</script>
