<template>
  <b-col>
    <b-row v-for="phase in phases" :key="phase">
      <b-col>
        <h3>{{raceName}} {{phase}}</h3>
        <b-row
          v-for="resultsForBoatType in resultsPerBoatType.filter(e=> e.phase===phase)"
          :key="resultsForBoatType.boatType"
        >
          <b-col>
            <h5>{{resultsForBoatType.boatType}}</h5>
            <b-table
              striped borderless small
              tbody-tr-class="no-padding"
              :items="resultsForBoatType.results"
              :fields="fields"
            >
              <template #head(details)="">
                <b-icon-plus-circle
                  scale="0.80"
                  @click="toggleDetailsAllRows(resultsForBoatType.results)"
                />
              </template>
              <template v-slot:cell(details)="row">
                <b-icon-plus-circle scale="0.80" @click="row.toggleDetails"/>
              </template>
              <template v-slot:row-details="row">
                <b-card>
                  <runs-details :runs="row.item.runs">
                  </runs-details>
                </b-card>
              </template>
              <template v-slot:cell(name)="data">
                <router-link :to="'/boat/'+data.item.boatId">
                {{ data.item.boat.label }}
                </router-link>
              </template>
              <template v-slot:cell(clubs)="data">
                <span
                  v-for="(club, index) in data.item.clubs"
                  :key="data.item.boatId+'-club'+index"
                  href="#"
                >
                  {{index ? ' - ' : ''}}{{club.clubName}}
                </span>
              </template>
              <!-- <template v-slot:cell(name)="data">
                <router-link :to="'/race/'+data.item.raceId">
                  {{ getRaceName(data.item.nameEvent, data.item.place, data.item.raceName) }}
                </router-link>
              </template>-->

              <template v-slot:cell(points)="data">
                <span :class="{
                    red:data.item.points > data.item.value,
                    green:data.item.points < data.item.value
                  }"
                >
                  {{data.item.points > -1 ? data.item.points : '-'}}
                  </span>
              </template>
              <template v-slot:cell(value)="data">
                {{data.item.value > -1 ? data.item.value : '-'}}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <hr/>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import orderBy from 'lodash.orderby';
import RunsDetails from './RunsDetails.vue';

export default {
  name: 'RaceResults',
  components: {
    RunsDetails,
  },
  props: ['results', 'raceName'],
  data() {
    return {
      resultsPerBoatType: [],
      phases: [],
      fields: [
        { key: 'details', label: '' },
        { key: 'rank', label: 'Place' },
        { key: 'name', label: 'Nom' },
        { key: 'clubs', label: 'Club(s)' },
        { key: 'category', label: 'Cat' },
        { key: 'score', label: 'Résultat' },
        { key: 'value', label: 'Valeur' },
        { key: 'points', label: 'Pts course' },
      ],
    };
  },
  watch: {
    results() {
      this.resultsPerBoatType = [];
      this.prepareResults();
    },
  },
  created() {
    this.prepareResults();
  },
  methods: {
    prepareResults() {
      if (Array.isArray(this.results)) {
        const resultsPerBoatTypeAndPhase = {};
        this.results.forEach((result) => {
          const key = result.boatType + result.phase;
          if (!resultsPerBoatTypeAndPhase[key]) {
            resultsPerBoatTypeAndPhase[key] = {
              phase: result.phase,
              boatType: result.boatType,
              results: [],
            };
          }
          resultsPerBoatTypeAndPhase[key].results.push({ ...result, _showDetails: false });
        });
        const phases = new Set();
        Object.keys(resultsPerBoatTypeAndPhase).forEach((key) => {
          this.resultsPerBoatType.push({
            boatType: resultsPerBoatTypeAndPhase[key].boatType,
            phase: resultsPerBoatTypeAndPhase[key].phase,
            results: orderBy(resultsPerBoatTypeAndPhase[key].results, ['rank'], ['asc']),
          });
          phases.add(resultsPerBoatTypeAndPhase[key].phase);
        });
        this.phases = [...phases];
      }

      this.resultsPerBoatType = orderBy(this.resultsPerBoatType, ['boatType'], ['asc']);
    },
    toggleDetailsAllRows(rows) {
      rows.forEach((row) => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        row._showDetails = !row._showDetails;
      });
    },
  },
};
</script>

<style scoped>
  .card-body {
    padding: 0.25rem;
  }
</style>
